<template>
  <section class="page__title-area pt-20 pb-30">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div class="page__title-content mb-25">
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item" style="margin:0 10px;">
                      <router-link to="/">{{ $t("Home") }} </router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/terms-of-use">
                        {{ $t("Terms of Use") }}</router-link
                      >
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{ $t("Research Studies Services") }}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__tab-conent">
            <div class="tab-content" id="courseTabContent">
              <div
                class="tab-pane fade show active"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>

                        <div
                          class="course__teacher"
                          :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                        >
                          <ul>
                            <li>
                              <strong>{{ $t("Research Support") }}</strong
                              >:
                            </li>
                            <ul>
                              <li>
                                {{
                                  $t(
                                    "Provide help in accessing to funding opportunities, grants, and scholarships for research projects."
                                  )
                                }}
                              </li>
                            </ul>
                            <li>
                              <strong>{{ $t("Networking Opportunities") }}</strong
                              >:
                            </li>
                            <ul>
                              <li>
                                {{
                                  $t(
                                    "Organize seminars to connect researchers and academics for possible research opportunities available for students."
                                  )
                                }}
                              </li>
                              <li>
                                {{
                                  $t(
                                    "Facilitate collaborations between students, faculty, and external research organizations."
                                  )
                                }}
                              </li>
                            </ul>
                            <li>
                              <strong>{{ $t("Resources and Facilities") }}</strong
                              >:
                            </li>
                            <ul>
                              <li>
                                {{
                                  $t(
                                    "Offer a research hub environment so students are able to access research databases, journals, and other scholarly resources for conducting research."
                                  )
                                }}
                              </li>
                            </ul>
                            <li>
                              <strong>{{ $t("Training and Development") }}</strong
                              >:
                            </li>
                            <ul>
                              <li>
                                {{
                                  $t(
                                    "Host workshops on research related activities of AI including data analysis, data collection and available common analysis tools."
                                  )
                                }}
                              </li>
                            </ul>
                            <li>
                              <strong>{{ $t("Compliance and Ethics") }}</strong
                              >:
                            </li>
                            <ul>
                              <li>
                                {{
                                  $t(
                                    "Help in accessing academic institutions resources on research ethics, integrity, and compliance with institutional policies."
                                  )
                                }}
                              </li>
                            </ul>
                          </ul>
                          <p>
                            {{
                              $t(
                                "These services aim to foster a thriving research environment, enhancing the academic and professional development of graduate students."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MsAnalytics",
  components: {},
};
</script>

<style scoped>
p {
  font-size: 15px;
}

.course__teacher ul ul li {
  list-style: disc;
  margin: 0 40px;
}

</style>
