<template>
  <footer>
    <div :class="`footer__area ${footerBgTwo ? 'grey-bg-2' : 'footer-bg'}`">
      <div :class="`footer__top ${footerPadding ? 'pt-90' : 'pt-190'} pb-40`">
        <div class="container">
          <div class="row">
            <div class="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6">
              <div class="footer__widget mb-50">
                <div class="footer__widget-head mb-22">
                  <div class="footer__logo">
                    <router-link to="/">
                      <img
                        style="width: 25%"
                        :src="`${
                          footerBgTwo
                            ? require('../../assets/img/logo/logo.svg')
                            : require('../../assets/img/logo/logo.svg')
                        }`"
                        alt=""
                      />
                    </router-link>
                  </div>
                </div>
                <div
                  :class="`${
                    footerBgTwo
                      ? 'footer__widget-body-2'
                      : 'footer__widget-body'
                  }`"
                >
                  <p style="text-align: justify">
                    {{
                      $t(
                        "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                      )
                    }}
                  </p>

                  <div class="footer__social">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/share/BkxfeV3b45VTk9i9" aria-label="Facebook"><i class="fab fa-facebook-f"></i></a>
                      </li>
                      <li>
                        <a href="https://www.instagram.com/studigriduae" class="tw" aria-label="Linkedin"
                          ><i class="fab fa-instagram"></i
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-xxl-2 offset-xxl-1 col-xl-2 offset-xl-1 col-lg-3 offset-lg-0 col-md-2 offset-md-1 col-sm-5 offset-sm-1"
            >
              <div class="footer__widget mb-50">
                <div class="footer__widget-head mb-22">
                  <h3
                    :class="`${
                      footerBgTwo
                        ? 'footer__widget-title-2'
                        : 'footer__widget-title'
                    }`"
                  >
                    {{ $t("Company") }}
                  </h3>
                </div>
                <div class="footer__widget-body">
                  <div
                    :class="`${
                      footerBgTwo ? 'footer__link-2' : 'footer__link'
                    }`"
                  >
                    <ul>
                      <li>
                        <router-link to="/about">{{ $t("About Us") }}</router-link>
                      </li>
                      <li>
                        <router-link to="/services">{{ $t("Services") }}</router-link>
                      </li>
                      <li>
                        <router-link to="/distance-edu">{{ $t("UK Distance Education") }}</router-link>
                      </li>
                      <li>
                        <router-link to="/contact">{{ $t("ContactUs") }}</router-link>
                      </li>
                      <li>
                        <router-link to="/useful-links">{{ $t("Useful Links") }}</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="col-xxl-2 col-xl-2 col-lg-2 offset-lg-0 col-md-3 offset-md-1 col-sm-6"
            >
              <div class="footer__widget mb-50">
                <div class="footer__widget-head mb-22">
                  <h3
                    :class="`${
                      footerBgTwo
                        ? 'footer__widget-title-2'
                        : 'footer__widget-title'
                    }`"
                  >
                    {{ $t("Platform") }}
                  </h3>
                </div>
                <div class="footer__widget-body">
                  <div
                    :class="`${
                      footerBgTwo ? 'footer__link-2' : 'footer__link'
                    }`"
                  >
                    <ul>
                      <li>
                        <router-link to="/distance-edu">{{
                            $t("Distance Education")
                          }}</router-link>
                      </li>
                      <li>
                        <router-link to="/why-study-uk">{{ $t("Why Study in Uk") }}</router-link>
                      </li>
                      <li>
                        <router-link to="/uk-accreditation">{{
                            $t("UK Accreditation")
                          }}</router-link>
                      </li>
                      <li>
                        <router-link to="/data-privacy">{{ $t("Data Privacy") }}</router-link>
                      </li>
                      <li>
                        <router-link to="/terms-of-use">{{ $t("Terms of Use") }}</router-link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-5 col-sm-6">
              <div class="footer__widget footer__pl-70 mb-50">
                <div class="footer__widget-head mb-22">
                  <h3
                    :class="`${
                      footerBgTwo
                        ? 'footer__widget-title-2'
                        : 'footer__widget-title'
                    }`"
                  >
                    {{ $t("Subscribe") }}
                  </h3>
                </div>
                <div class="footer__widget-body">
                  <div
                    :class="`${
                      footerBgTwo ? 'footer__subscribe-2' : 'footer__subscribe'
                    }`"
                  >
                    <form action="#">
                      <div class="footer__subscribe-input mb-15">
                        <input type="email" placeholder="Email " />
                        <button type="submit" aria-label="submit">
                          <i class="far fa-arrow-right"></i>
                          <i class="far fa-arrow-right"></i>
                        </button>
                      </div>
                    </form>
                    <p>
                      {{
                        $t(
                          "Get the latest news and updates right at your inbox."
                        )
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        :class="`${
          footerBgTwo ? 'footer__bottom footer__bottom-2' : 'footer__bottom'
        }`"
      >
        <div class="container">
          <div class="row">
            <div class="col-xxl-12">
              <div
                :class="`${
                  footerBgTwo
                    ? 'footer__copyright-2 text-center'
                    : 'footer__copyright text-center'
                }`"
              >
                <p>
                  {{ $t("© 2022 Studigrid, All Rights Reserved. Design By") }}
                  <router-link to="https://mindtocode.org/">
                    {{ $t("MTC") }}
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterArea",
  props: {
    footerBgTwo: String,
    footerPadding: String,
  },
};
</script>


<style scoped>

.footer__social ul li a.tw {
    background: #fbad50;
    border-color: #fbad50;
}
.footer__social ul li a.tw:hover {
    background: transparent;
    color: white;
}
</style>