<template>
  <section class="page__title-area pt-20 pb-30">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div class="page__title-content mb-25">
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item" style="margin:0 10px;">
                      <router-link to="/">{{ $t("Home") }} </router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/terms-of-use"> {{
                        $t("Terms of Use")
                      }}</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                       {{ $t("Student Admission and Consultation Services") }}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__tab-conent">
            <div class="tab-content" id="courseTabContent">
              <div
                class="tab-pane fade show active"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <div class="row">
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div class="course__item white-bg mb-30 fix">
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>

                        <div class="course__teacher" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
                          <ul>
                            <li><strong>{{ $t("Information and Guidance") }}</strong>:</li>
                            <ul>
                              <li>
                                {{ $t("Provide details on available graduate programs, entry requirements, and application procedures.") }}
                              </li>
                              <li>
                                {{ $t("Offer advice on choosing the right course and institution.") }}
                              </li>
                            </ul>
                            <li><strong>{{ $t("Application Support") }}</strong>:</li>
                            <ul>
                              <li>
                                {{ $t("Assist with the completion of online application forms.") }}
                              </li>
                              <li>
                                {{ $t("Provide tips for writing personal statements and preparing resumes.") }}
                              </li>
                            </ul>
                            <li><strong>{{ $t("Documentation Assistance") }}</strong>:</li>
                            <ul>
                              <li>
                                {{ $t("Guidance on required documents (transcripts, references, English proficiency tests).") }}
                              </li>
                              <li>{{ $t("Support for submitting official documents.") }}</li>
                            </ul>
                            <li><strong>{{ $t("Admissions Processing") }}</strong>:</li>
                            <ul>
                              <li>
                                {{ $t("Review and evaluate applications against program criteria.") }}
                              </li>
                              <li>
                                {{ $t("Communicate admission decisions and next steps to applicants.") }}
                              </li>
                            </ul>
                            <li><strong>{{ $t("Financial Advice") }}</strong>:</li>
                            <ul>
                              <li>
                                {{ $t("Provide information on tuition fees, scholarships, and funding opportunities.") }}
                              </li>
                              <li>
                                {{ $t("Assist with financial planning and budgeting for studies.") }}
                              </li>
                            </ul>
                            <li><strong>{{ $t("Ongoing Communication") }}</strong>:</li>
                            <ul>
                              <li>
                                {{ $t("Maintain contact with applicants throughout the admission process.") }}
                              </li>
                              <li>
                                {{ $t("Provide updates and respond to queries in a timely manner.") }}
                              </li>
                            </ul>
                            <li><strong>{{ $t("Feedback Mechanism") }}</strong>:</li>
                            <ul>
                              <li>
                                {{ $t("Collect and analyze feedback from applicants to improve services.") }}
                              </li>
                              <li>
                                {{ $t("Implement changes based on student experiences and suggestions.") }}
                              </li>
                            </ul>
                          </ul>
                          <p>
                            {{ $t("These services aim to facilitate a smooth and informed application process for prospective graduate students in the UK.") }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MsAnalytics",
  components: {},
};
</script>

<style scoped>
p {
  font-size: 15px;
}

.course__teacher ul ul li {
  list-style: disc;
  margin: 0 40px;
}
</style>
