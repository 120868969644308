<template>
  <section class="page__title-area pt-20 pb-30">
    <div class="page__title-shape">
      <img
        class="page-title-shape-5 d-none d-sm-block"
        src="../../assets/img/page-title/page-title-shape-1.webp"
        alt=""
      />
      <img
        class="page-title-shape-6"
        src="../../assets/img/page-title/page-title-shape-6.webp"
        alt=""
      />
      <img
        class="page-title-shape-7"
        src="../../assets/img/page-title/page-title-shape-4.webp"
        alt=""
      />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-xxl-12 col-xl-12 col-lg-12">
          <div class="course__wrapper">
            <div
              class="page__title-content mb-25"
              :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            >
              <div class="page__title-breadcrumb">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                      <router-link to="/">{{ $t("Home") }}</router-link>
                    </li>
                    <li class="breadcrumb-item">
                      <router-link to="/ms-data">
                        {{ $t("MS Data Science") }}
                      </router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">
                      {{
                        $t(
                          "Studigrid is an educational and technology service that facilitates access to distance higher education from UK universities for students in the GCC."
                        )
                      }}
                    </li>
                  </ol>
                </nav>
              </div>
              <span class="page__title-pre">{{ $t("MS Data Science") }}</span>
            </div>
            <div class="row" :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
              <div class="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                <div class="course__tab-content mb-95">
                  <div class="tab-content" id="courseTabContent">
                    <div
                      class="tab-pane fade show active"
                      id="description"
                      role="tabpanel"
                      aria-labelledby="description-tab"
                    >
                      <div class="course__description">
                        <p>
                          {{
                            $t(
                              "A Masters of Data Science program in the UK is an advanced, intensive course of study designed to impart a deep understanding of data analysis, machine learning, and statistical methods, typically over one year full-time or two years part-time. The curriculum is structured to provide students with both theoretical knowledge and practical skills in handling, analyzing, and interpreting large datasets. Students learn through a combination of lectures, workshops, project work, and often a dissertation focused on solving real-world data challenges."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "The program covers various areas, including statistics, programming in languages such as Python and R, machine learning algorithms, data visualization, and the ethical considerations of data use. It emphasizes the application of data science techniques across different sectors like finance, healthcare, marketing, and technology, preparing students for the rapidly evolving data landscape."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "Graduates from a Masters of Data Science program in the UK are well-equipped for a wide range of careers in data-intensive industries. Potential job roles include Data Scientist, Machine Learning Engineer, Data Analyst, Business Intelligence Analyst, Data Engineer, and Analytics Consultant. These positions are sought after in tech companies, consultancy firms, financial institutions, healthcare organizations, and governmental agencies, reflecting the broad applicability and high demand for data science skills in today's job market."
                            )
                          }}
                        </p>
                        <p>
                          {{
                            $t(
                              "A typical one-year Masters of Data Science program in the UK is structured to offer a blend of theoretical knowledge and practical skills in data science, analytics, and machine learning. The program is intensive and designed to prepare students for the rapidly evolving field of data science. Here is an illustrative structure for the program:"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                <img
                  src="../../assets/img/Ms_Data_Science/data-science-2.webp"
                  style="width: 100%"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="col-xxl-12 col-xl-12 col-lg-12"
          :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
        >
          <div class="course__tab-conent">
            <div class="tab-content" id="courseTabContent">
              <div
                class="tab-pane fade show active"
                id="grid"
                role="tabpanel"
                aria-labelledby="grid-tab"
              >
                <div class="row">
                  <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                    <div
                      class="course__item white-bg fix"
                      style="height: 335px; margin-top: -70px"
                    >
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">{{ $t("Term 1") }}</h3>
                        <div class="course__teacher">
                          <p>
                            {{
                              $t(
                                "Introduction to Data Science: Fundamentals of data science, including data analysis and manipulation techniques."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "Statistics for Data Science: Basic and advanced statistical methods essential for data analysis."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "Programming for Data Science: Introduction to programming languages popular in data science, such as Python or R."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                    <img
                      src="../../assets/img/Ms_Data_Science/data-science-3.webp"
                      style="width: 100%; height: 70%; margin-top: -50px"
                      alt=""
                    />
                  </div>
                  <div class="col-xxl-5 col-xl-5 col-lg-5 col-md-5">
                    <img
                      src="../../assets/img/Ms_Data_Science/data-science-1.webp"
                      style="width: 100%; height: 70%; margin-top: -50px"
                      alt=""
                    />
                  </div>
                  <div class="col-xxl-7 col-xl-7 col-lg-7 col-md-7">
                    <div
                      class="course__item white-bg fix"
                      style="height: 330px; margin-top: -70px"
                    >
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">{{ $t("Term 2") }}</h3>
                        <div class="course__teacher">
                          <p>
                            {{
                              $t(
                                "Machine Learning: In-depth study of machine learning algorithms and their applications."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "Big Data Technologies: Exploration of technologies and frameworks for handling big data, such as Hadoop, Spark, and NoSQL databases."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "Data Mining and Visualisation: Techniques for discovering patterns in large datasets and visualising data."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12">
                    <div
                      class="course__item white-bg fix"
                      style="margin-top: -110px"
                    >
                      <div class="course__content">
                        <div
                          class="course__meta d-flex align-items-center justify-content-between"
                        ></div>
                        <h3 class="course__title">
                          {{ $t("Summer Term and Beyond:") }}
                        </h3>
                        <div class="course__teacher">
                          <p>
                            {{
                              $t(
                                "Dissertation/Research Project: Students undertake a substantial project or dissertation, applying data science techniques to a real-world problem or a novel research question. This is a chance to specialize in an area of interest, such as deep learning, natural language processing, or bioinformatics."
                              )
                            }}
                          </p>
                          <p>
                            {{
                              $t(
                                "This program structure is designed to equip graduates with the necessary skills for a variety of roles in the field of data science, including Data Scientist, Data Analyst, Machine Learning Engineer, Data Engineer, and Business Intelligence Analyst."
                              )
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "MsData",
  components: {},
};
</script>
